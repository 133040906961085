@font-face {
  font-family: "Rutan";
  src: url("rutan.ttf");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Rutan-Bold";
  src: url("rutan-bold.ttf");
  font-style: normal;
  font-weight: 700;
}